import dayjs from "dayjs"
import './Schedule.css';
import LiveNowCard from '../components/cards/LiveNowCard';
import StartingSoonCard from '../components/cards/StartingSoonCard';
import { Game } from "../types/game";

type ScheduleProps = {
  onSelect: (game: Game) => void;
};

function Schedule({ onSelect }: ScheduleProps) {
  // NOTE: these are inside the component because dayjs needs to boot
  const LIVE_NOW = [
    {
      id: 2,
      home: {
        long_name: 'Marshall University',
        short_name: 'Marshall',
        logo: 'https://clients.sidearmsports.com/sidearm_files/logos/conference/marshall_200x200.png',
      },
      away: {
        long_name: 'Appalachian State University',
        short_name: 'App State',
        logo: 'https://clients.sidearmsports.com/sidearm_files/logos/sun_belt/Appalachian-State-University.png',
      },
      venue: 'Marshall University',
      starts_at: dayjs.utc('2024-10-05 19:30:00'),
    },
  ];

  const STARTING_SOON = [
    {
      id: 1,
      home: {
        long_name: 'Longbeach State University',
        short_name: 'LBSC',
        logo: 'https://clients.sidearmsports.com/sidearm_files/logos/big_west/long_beach_state.png',
      },
      away: {
        long_name: 'University of Southern California',
        short_name: 'USC',
        logo: 'https://clients.sidearmsports.com/sidearm_files/logos/sec/south_carolina.png',
      },
      venue: 'Longbeach State University',
      starts_at: dayjs.utc('2024-12-31 00:00:00'),
    },
  ];

  const live = [ ...LIVE_NOW ] as Game[];
  const soon = [ ...STARTING_SOON ] as Game[];

  return (
    <div className="Schedule">
      {live.map((game) => (
        <LiveNowCard
          key={game.id}
          {...game}
          onClick={() => onSelect(game)}
          />
      ))}

      {soon.length > 0
        ? <>
            <p className="Schedule-heading">STARTING SOON</p>

            {soon.map((game) => (
              <StartingSoonCard
                key={game.id}
                {...game}
                />
            ))}
          </>
        : null
      }
    </div>
  );
}

export default Schedule;
